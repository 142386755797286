<template>
  <div>
    <b-btn
        to="/dohled-nad-oz/cinnost-zastupcu"
        :variant="getButtonVariant('cinnostObchodnichZastupcu')"
    >
      Činnost obchodních zástupců
    </b-btn>
    <b-btn
        to="/dohled-nad-oz/ukolovani-zastupcu"
        :variant="getButtonVariant('ukolovaniObchodnichZastupcu')"
        class="ml-4"
    >
      Úkolování obchodních zástupců
    </b-btn>
    <b-btn
        to="/dohled-nad-oz/obchodni-rozpracovanost"
        :variant="getButtonVariant('obchodniRozpracovanost')"
        class="ml-4"
    >
      Obchodní rozpracovanost
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'dohled-tab-buttons',
  props: {
    tabName: {type: String, required: true},
  },
  methods: {
    getButtonVariant(btnName) {
      if (btnName === this.tabName) return 'primary';
      return 'outline-primary';
    },
  }
}
</script>