<template>
  <div v-if="isHaveAccess" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line d-flex justify-content-between pl-1 pr-2">
        <dohled-tab-buttons v-if="!isShowForCurrentOzUser" tabName="ukolovaniObchodnichZastupcu"/>
      </div>

      <div class="text-left filter-line d-flex">
        <template v-if="!isShowForCurrentOzUser">
          <span class="font-weight-bold pr-2 fs-17px pl-1">Filtry:</span>
          <b-form-select
              name="filterTypes"
              v-model="filterType"
              :options="listFilterTypes"
              class="form-select form-select-sm d-inline-block w-230px text-black"
              size="sm"
              @change="changeFilterTypes"
          />

          <span class="bold ml-3 lh-2">Obchodní zástupce:</span>
          <b-form-input
              list="obchodniZastupceList"
              name="obchodniZastupce"
              class="form-control form-control-sm w-250px ml-2 text-black"
              placeholder="vše"
              v-model="filter.obchodniZastupceName"
              @change="changeOzUsers"
          />
          <b-datalist
              id="obchodniZastupceList"
              :options="ozUsersNames"
              autocomplete="off"
          />

          <b-icon-x-circle
              @click="clearFilterGetInfo(false)"
              class="pl-2 pt-1 cursor-pointer"
              :class="[isFiltered() ? 'filtered' : '']"
              font-scale="1.8"
              title="Čištění filtru"
          />

          <span class="pl-5 pt-3px">
            [ výsledky: {{ totalItems }} ]
          </span>
        </template>
      </div>

      <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          responsive
          hover
          no-border-collapse
          class="stickyTable"
          :items="info"
          :fields="headers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-direction="desc"
          @sort-changed="changeOrder"
      >
        <template #cell(orderNumber)="{ item }" class="color1 bg-color-white">
          <div class="d-flex justify-content-between pl-1 pr-1 text-left">
            <template v-if="$store.getters.isObchodniPripadModalAllowed">
              <a
                  href="#"
                  @click.prevent="openZakazkaModal(item)"
                  title="Upravit obchodní případ"
                  class="pr-2"
              >
                {{ item.orderNumber }}
              </a>
              <a
                  :href="`mailto:${item.personaEmail || ''}?subject=${item.orderNumber}`"
                  title="Poslat email zákazníkovi"
                  class="pl-3"
              >
                <b-icon-envelope-fill class="user-enabled-email"/>
              </a>
            </template>
            <span v-else>
              {{ item.orderNumber }}
            </span>
          </div>
        </template>

        <template #cell(stav)="{ item }" class="color1">
          <div class="text-center">
            <a v-if="$store.getters.isStavObchodnihoPripaduAllowed"
               @click.prevent="showStavObchodnihoPripaduModal(item)"
               title="Otevřít 'Stav obchodního případu' formulář"
               href="#"
            >
                    <span v-if="isStavSelected(item)" v-html="getStaveAndDate(item)"></span>
              <b-icon-pencil-square v-else/>
            </a>
            <span v-else>
              {{ getStaveAndDate(item) }}
            </span>
          </div>
        </template>

        <template #cell(poznamkySaveDate)="{ item }" class="color1">
          <div class="text-center">
            <a v-if="$store.getters.isPoznamkyKobchodnimuPripaduAllowed"
               @click.prevent="showPoznamkyKobchodnimuPripaduModal(item)"
               title="Otevřít 'Poznámky k obchodnímu případu' formulář"
               href="#"
            >
                        <span v-if="isShowPoznamkySaveDate(item)">
                          {{ getFormattedDate(item.poznamkySaveDate) }}
                        </span>
              <b-icon-pencil-square v-else/>
            </a>
            <span v-else>
                      {{ getFormattedDate(item.obchod.poznamkySaveDate) }}
                  </span>
          </div>
        </template>

        <template #cell(zaevidovanoDate)="{ item }">
          <div class="text-center">
            <b-icon-journal
                font-scale="1.3"
                variant="secondary"
                class="cursor-pointer"
                @click="showKartaPripaduModal(item)"
            />
            <div class="fs-11px pt-1">
              {{ item.zaevidovanoDate ? `${getFormattedDate(item.zaevidovanoDate)}` : ''}}
            </div>
          </div>
        </template>

        <template #cell(pocetDniPoPosledniAktivite)="{ item }">
              <span v-if="!item.posledniAktivitaDate" class="bold text-red">
                není
              </span>
          <span v-else>
                {{ item.pocetDniPoPosledniAktivite }}
              </span>
        </template>

        <template #cell(pocetDniDoNaplanovaneAktivity)="{ item }">
          <div v-if="!item.naplanovanaAktivitaDate" class="bold text-red">
            není
          </div>
          <div v-else v-html="getPocetDniDoNaplanovaneAktivity(item)"></div>
        </template>

        <template #cell(predpokladanaCena)="{ item }">
          {{ formatMoney(item.predpokladanaCena) }}
        </template>

        <template #cell(kraj)="{ item }">
          {{ getKrajName(item.kraj, lists.kraj) }}
        </template>

        <template #cell(GDPR)="{ item }" class="color1">
          <div class="text-center">
            <a v-if="$store.getters.isGDPRRodinnePrislusniciAllowed"
               @click.prevent="showGDPRRodinnePrislusniciModal(item)"
               title="Otevřít 'Rodinné příslušníci a GDPR' formulář"
               href="#"
            >
                    <span v-if="item.GDPRSaveDate">
                      {{ getFormattedDate(item.GDPRSaveDate) }}
                    </span>
              <b-icon-pencil-square v-else/>
            </a>
            <span v-else>
                  <span v-if="item.GDPRSaveDate">
                    {{ getFormattedDate(item.GDPRSaveDate) }}
                  </span>
                </span>
          </div>
        </template>

        <template #head()="{ field }">
          <span v-html="field.label"></span>
        </template>
      </b-table>

      <div v-if="!info || !info.length" class="pt-5">
        <h5 class="p-5 fs-23px">{{ getInfoEmptyText }}</h5>
      </div>

      <obchodni-pripad-form
          v-if="showObchodniPripadModal && $store.getters.isObchodniPripadModalAllowed"
          :id="getIdSelectedContract"
          :show="showObchodniPripadModal"
          :isNewObchodPripadFormCanBeSaved="isNewObchodPripadFormCanBeSaved"
          @close-modal="showObchodniPripadModal = false"
          @form-saved="formSaved"
          @show-existujici-obchodny-pripady-modal="showExistujiciObchodniPripadModal"
      />

      <poznamky-k-obchodnimu-pripadu-form
          v-if="showPoznamkyKobchodnimuPripaduForm && $store.getters.isPoznamkyKobchodnimuPripaduAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showPoznamkyKobchodnimuPripaduForm"
          @close-modal="showPoznamkyKobchodnimuPripaduForm = false"
          @form-saved="formSaved"
      />

      <karta-form
          v-if="showKartaPripaduForm"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showKartaPripaduForm"
          @close-modal="showKartaPripaduForm = false"
      />

      <stav-obchodniho-pripadu-form
          v-if="showStavObchodnihoPripaduForm && $store.getters.isStavObchodnihoPripaduAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showStavObchodnihoPripaduForm"
          @close-modal="showStavObchodnihoPripaduForm = false"
          @form-saved="formSaved"
      />

      <gpdr-form
          v-if="showGDPRRodinnePrislusniciForm && $store.getters.isGDPRRodinnePrislusniciAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showGDPRRodinnePrislusniciForm"
          @close-modal="showGDPRRodinnePrislusniciForm = false"
          @form-saved="formSaved"
      />
    </b-overlay>
  </div>
</template>


<script>
import DohledTabButtons from "@/views/DohledNadOz/DohledTabButtons.vue";
import {FILTER_TYPES, LIST_UKOLOVANI_OZ_FILTER_TYPES} from "@/constants/form.constants";
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";
import ObchodniPripadForm from "@/components/modals/ObchodniPripadForm.vue";
import _ from "lodash";
import moment from "moment/moment";
import PoznamkyKObchodnimuPripaduForm from "@/components/modals/PoznamkyPripojit.vue";
import KartaForm from "@/components/modals/KartaForm.vue";
import StavObchodnihoPripaduForm from "@/components/modals/StavObchodnihoPripaduForm.vue";
import GpdrForm from "@/components/modals/GDPRForm.vue";

const defaultSortDirection = true;

export default {
  components: {
    StavObchodnihoPripaduForm, GpdrForm,
    KartaForm, PoznamkyKObchodnimuPripaduForm, ObchodniPripadForm, DohledTabButtons
  },
  mixins: [commonMixin],
  data() {
    return {
      isLoading: false,
      info: [],
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      filterType: FILTER_TYPES.NENAPLANOVANE_AKTIVITY,
      listFilterTypes: LIST_UKOLOVANI_OZ_FILTER_TYPES,
      ozUsers: [],
      ozUsersNames: [],
      filter: {
        obchodniZastupceName: null,
        sortBy: null,
        sortDirection: defaultSortDirection,
      },
      abortController: new AbortController(),
      headers: [
        {label: 'Karta případu<br><span class="font-weight-normal fs-11px">(datum založení)</span>', key: 'zaevidovanoDate', variant: 'color-1', class: 'pl-1 pr-1 mw-90px pt-2 pb-2', sortable: true},
        {label: 'Číslo obchodního případu<br><span class="font-weight-normal">(název adresáře v poptávkách / zakázkách)</span>', key: 'orderNumber', stickyColumn: true, variant: 'color-1', class: 'mw-400px stickyColumn', sortable: true},
        {label: 'Kraj instalace', key: 'kraj', variant: 'color-1', class: 'mw-160px', sortable: true},
        {label: 'Město (obec) instalace', key: 'cityInstallation', variant: 'color-1', class: 'mw-200px', sortable: true},
        {label: 'Kontaktní údaje<br><span class="font-weight-normal">(GDPR, plné moci)</span>', key: 'GDPR', variant: 'color-1', class: 'mw-145px'},
        {label: 'Poznámky<br><span class="font-weight-normal">(textový popis historie)</span>', key: 'poznamkySaveDate', variant: 'color-1 mw-115px', sortable: true},
        {label: 'Počet dní po poslední aktivitě', key: 'pocetDniPoPosledniAktivite', variant: 'color-1', sortable: true},
        {label: 'Počet dní do naplánované aktivity', key: 'pocetDniDoNaplanovaneAktivity', variant: 'color-1', sortable: true},
        {label: 'Stav obchodního případu<br><span class="font-weight-normal">(plánovaní aktivit)</span>', key: 'stav', variant: 'color-1', class: 'mw-220px', sortable: true},
        {label: 'Hodnota obchodního případu', key: 'predpokladanaCena', variant: 'color-1', sortable: true},
      ],
      selectedContract: null,
      showKartaPripaduForm: false,
      showObchodniPripadModal: false,
      showExistujiciObchodniPripadForm: false,
      showPoznamkyKobchodnimuPripaduForm: false,
      showGDPRRodinnePrislusniciForm: false,
      showStavObchodnihoPripaduForm: false,
      isNewObchodPripadFormCanBeSaved: false,
      newObchodPripadData: {},
      curDateStart: moment().startOf('day'),
      sortBy: 'zaevidovanoDate',
      sortDesc: true,
      lists: {
        kraj: [],
        stav: [],
      },
      totalItems: 0,
    };
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.isDohledNadOzPageAllowed || !!this.$store.getters.getUserNumberOZ;
    },
    getIdSelectedContract() {
      return this.selectedContract?._id;
    },
    isShowForCurrentOzUser() {
      return _.get(this.$route, 'meta.filterType', false);
    },
    getInfoEmptyText() {
      return this.filterType === FILTER_TYPES.NENAPLANOVANE_AKTIVITY ? 'Nenaplánované aktivity nejsou' : 'Nesplněné aktivity nejsou';
    },
  },
  methods: {
    filterTypeFromMeta() {
      return _.get(this.$route, 'meta.filterType', null);
    },
    async changeFilterTypes() {
      await this.clearFilterGetInfo(false);
    },
    isFiltered() {
      return this.filter.obchodniZastupceName;
    },
    isStavSelected(item) {
      return !!_.get(item, 'stav', false);
    },
    async clearFilterGetInfo(noCountQuery = false) {
      this.filter.obchodniZastupceName = null;
      this.offset = 0;
      this.filter.sortBy = null;
      this.filter.sortDirection = defaultSortDirection;
      this.resetScrollTop();
      await this.getInfo(noCountQuery);
    },
    async changeOzUsers() {
      this.offset = 0;
      await this.getInfo();
    },
    async getOzUsersGroupsForUser() {
      try {
        const url = `/user/getOzUsersGroupsForUser`;
        const {data} = await axios.get(url, {});
        this.ozUsersNames = data?.data?.activeOzUsersNamesFioTitles || [];
        this.ozUsers = data?.data?.activeOzUsersInfo || [];
      } catch (error) {
        console.log(error);
      }
    },
    getStaveAndDate(elem) {
      if (!elem) return '';
      const stavDateString = elem?.stavDate ? `<br>(${this.getFormattedDate(elem.stavDate)})` : '';
      const stavConstant = this.lists.stav.filter(c => c.value === elem?.stav);
      const stavText = stavConstant?.length ? stavConstant[0].text : '';
      return elem?.stav ? `${stavText}${stavDateString}` : '';
    },
    showPoznamkyKobchodnimuPripaduModal(item) {
      this.selectedContract = item;
      this.showPoznamkyKobchodnimuPripaduForm = true;
    },
    showKartaPripaduModal(item) {
      this.selectedContract = item;
      this.showKartaPripaduForm = true;
    },
    showStavObchodnihoPripaduModal(item) {
      this.selectedContract = item;
      this.showStavObchodnihoPripaduForm = true;
    },
    showGDPRRodinnePrislusniciModal(item) {
      this.selectedContract = item;
      this.showGDPRRodinnePrislusniciForm = true;
    },
    isShowPoznamkySaveDate(item) {
      return !!_.get(item, 'poznamkySaveDate', false);
    },
    openZakazkaModal(item) {
      this.selectedContract = item;
      this.showObchodniPripadModal = true;
    },
    async formSaved() {
      await this.clearFilterGetInfo(false);
    },
    showExistujiciObchodniPripadModal(formData) {
      this.showExistujiciObchodniPripadForm = true;
      this.newObchodPripadData = formData;
    },
    getPocetDniDoNaplanovaneAktivity(item) {
      const dayCounts = item.pocetDniDoNaplanovaneAktivity;
      let stringInfo = '';
      if (item.activityDone) {
        stringInfo = `${dayCounts}<br>(splněno)`;
      } else if (dayCounts < 0) {
        stringInfo = `<span class="bold text-red">${dayCounts}</span>`;
      } else if (dayCounts > 0) {
        stringInfo = `<span class="bold dark-blue">${dayCounts}</span>`;
      } else {
        stringInfo = `${dayCounts}`;
      }
      return stringInfo;
    },
    getSelectedOzUserId() {
      return _.first(this.ozUsers.filter(u => u.fullNameFioTitles === this.filter.obchodniZastupceName))?.id;
    },
    async getConstants() {
      const dataConst = await this.getConstantsFromServer(['KRAJ_SHORT', 'STAV_STATUSES']) || {};
      this.lists.kraj = dataConst.KRAJ_SHORT;
      this.lists.stav = dataConst.STAV_STATUSES;
    },
    async getInfoForCurrentOzUser(/*noCountQuery*/) {
      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        this.isLoading = true; // !noCountQuery; add this to disable loader if scroll
        const url = `/dohled/getUkolovaniOzInfoUser`;
        const query = {
          offset: this.offset,
          limit: this.limit,
          ozUserId: this.$store.getters.userInfo._id,
          filterType: this.filterTypeFromMeta(),
        };
        const {data: {data}} = await axios.post(url, query, {signal: this.abortController.signal});
        if (!this.offset) {
          this.info = data;
        } else {
          this.info.push(...data);
        }
        this.offset = this.offset > this.info.length ? this.offset : this.limit + this.offset;
      } catch (e) {
        console.error('getInfoForCurrentOzUser', e.response?.data?.message);
      } finally {
        this.loading = false;
        this.isLoading = false;
      }
    },
    async getInfo(noCountQuery = false) {
      await Promise.all([
        this.getUkolovaniOzInfo(noCountQuery),
        this.getInfoCount(noCountQuery),
      ]);
    },
    async getUkolovaniOzInfo(noCountQuery) {
      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        if (this.isShowForCurrentOzUser) {
          await this.getInfoForCurrentOzUser(noCountQuery);
          return;
        }

        const filterByUserId = this.getSelectedOzUserId();
        this.isLoading = true; // !noCountQuery; add this to disable loader if scroll (for fast queries)
        const url = `/dohled/getUkolovaniOzInfo`;
        const {data: {data}} = await axios.post(url, {
          offset: this.offset,
          limit: this.limit,
          ozUserId: filterByUserId,
          filterType: this.filterType,
          filter: this.filter,
        },
            {signal: this.abortController.signal}
        );

        if (!this.offset) {
          this.info = data;
        } else {
          this.info.push(...data);
        }
        this.offset = this.offset > this.info.length ? this.offset : this.limit + this.offset;
      } catch (e) {
        console.error('getInfo', e.response?.data?.message);
      } finally {
        this.loading = false;
        this.isLoading = false;
      }
    },
    async getInfoCount(noCountQuery) {
      try {
        if (noCountQuery) return;

        const filterByUserId = this.getSelectedOzUserId();
        const url = `/dohled/getUkolovaniOzInfoCount`;
        const {data: {data}} = await axios.post(url, {
          ozUserId: this.isShowForCurrentOzUser ? this.$store.getters.userInfo._id : filterByUserId,
          filterType: this.filterType,
          filter: this.filter,
        });

        this.totalItems = data?.total || 0;
      } catch (e) {
        console.error('getObchodniPripadyCount', e);
        throw new Error()
      }
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    async changeOrder(ctx) {
      this.filter.sortBy = ctx.sortBy;
      this.filter.sortDirection = ctx.sortDesc;
      this.offset = this.offset - this.limit;
      this.resetScrollTop();
      await this.getInfo(true);
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.$route.query?.type) {
      this.filterType = this.$route.query.type;
    }
    await this.getConstants();
    await this.getInfo();
    await this.getOzUsersGroupsForUser();
    const table = this.$el?.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },
  watch: {
    $route() {
      if (this.$route.query?.type) {
        this.filterType = this.$route.query.type;
      }
      this.offset = 0;
      this.resetScrollTop();
      this.getInfo();
    },
  },
};
</script>

<style>
 .lh-2 {
   line-height: 2;
 }
</style>
